import axiosIns from '@/libs/axios'

export default {
  getInstitute: (() => axiosIns.get('accounting/fintech-merchant/getInstitute')),
  getAll: (instituteId => axiosIns.get(`accounting/fintech-merchant/get-fintech-merchant-by-institute-id/${instituteId}`)),
  settlementAccount: (instituteId => axiosIns.get(`accounting/fintech-merchant/settlement-account-name/${instituteId}`)),
  fintech: (() => axiosIns.get('accounting/fintech-merchant/fintech')),
  fintechService: (id => axiosIns.get(`accounting/fintech-merchant/service/${id}`)),
  save: (payload => axiosIns.post('accounting/fintech-merchant/save', payload)),
  update: ((Id, payload) => axiosIns.put(`accounting/fintech-merchant/update/${Id}`, payload)),
  getOne: (Id => axiosIns.get(`accounting/fintech-merchant/service/${Id}`)),
  delete: (Id => axiosIns.put(`accounting/fintech-merchant/delete/${Id}`)),
}
