<template>
  <b-card>

    <b-modal
      id="fintech-merchant-add-modal"
      :title="`${updateId ? 'Update' : 'Add'} Fintech Merchant`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok.prevent="handleFintechFormSubmit"
      @cancel="resetFintech"
      @close="resetFintech"
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Fintech Name -->
          <b-col cols="12">
            <b-form-group
              label="Fintech Name"
              invalid-feedback="Fintech Name is required"
              :state="fintech_idState"
              label-cols-md="4"
            >
              <v-select
                v-model="fintech_id"
                placeholder="Select Fintech name"
                label="name"
                :options="getFintech_id"
                :state="fintech_idState"
                :reduce="val => val.id"
                @input="getServiceById"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Service Category"
              invalid-feedback="Service Category is required"
              :state="payment_service_category_idState"
              label-cols-md="4"
            >
              <v-select
                v-model="payment_service_category_id"
                placeholder="Select Service category"
                label="name"
                taggable
                :options="getPayment_service_category_id"
                :state="payment_service_category_idState"
                :disabled="!fintech_id"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Merchant Name"
              invalid-feedback="Merchant Name is required"
              :state="merchant_nameState"
              label-cols-md="4"
            >
              <b-form-input
                v-model="merchant_name"
                :state="merchant_nameState"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Settlement Account Name"
              invalid-feedback="Settlement Account Name is required"
              :state="settlement_account_idState"
              label-cols-md="4"
            >

              <v-select
                v-model="settlement_account_id"
                placeholder="Select Settlement Account name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="getSettlement_account_id"
                :state="settlement_account_idState"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>

        </b-row>

      </b-form>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Fintech Merchant
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add Fintech Merchant
        </b-button>
      </b-form-group>
      <b-form-group>
        <v-select
          v-model="instituteId"
          class="style-chooser"
          placeholder="Select Institute"
          label="name"
          :options="filterInstitute"
          :reduce="val => val.id"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-if="props.column.field === 'status'">
          {{ props.formattedRow[props.column.field] == 1 ? 'Active' : 'Inactive' }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','30','40','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BPagination,
  BButton, BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import { RefreshCwIcon } from 'vue-feather-icons'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import FintechMerchantService from '@/@service/api/accounting/fintech/FintechMerchant.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    VueGoodTable,
    BFormSelect,
    BPagination,
    BButton,
    RefreshCwIcon,
    vSelect,
    BLink,
    ActivityModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      columns: [
        {
          label: 'Service Category',
          field: 'service_category',
          filterOptions: { enabled: true },
          sortable: false,
        },
        {
          label: 'Merchant name',
          field: 'merchant_name',
          filterOptions: { enabled: true },
          sortable: false,
        },
        {
          label: 'Settlement Account',
          field: 'settlement_account',
          filterOptions: { enabled: true },
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      tempRows: [],
      fintechFormData: {
        categories: [],
      },
      campusOptions: [],
      classOptions: [],
      fintechCategoryOptions: [],

      fintech_id: '',
      getFintech_id: [],
      payment_service_category_id: '',
      getPayment_service_category_id: [],
      merchant_name: '',
      settlement_account_id: '',
      getSettlement_account_id: [],

      fintech_idState: null,
      payment_service_category_idState: null,
      merchant_nameState: null,
      settlement_account_idState: null,

      instituteId: null,
      filterInstitute: [],

      tag: 'fintech-merchant',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    instituteId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getFintechGridDataByInstituteId()
      }
    },
  },
  created() {},
  mounted() {
    this.getInstitute()
  },
  methods: {
    getInstitute() {
      FintechMerchantService.getInstitute()
        .then(res => {
          this.filterInstitute = res.data.data
          this.instituteId = this.filterInstitute[0].id
          if (!this.instituteId) {
            FemsToastrService.error('Institute ID not found')
          }
        })
    },
    getFintechGridDataByInstituteId() {
      if (!this.instituteId) {
        this.rows = []
        return
      }
      FintechMerchantService.getAll(this.instituteId)
        .then(res => {
          if (res.data.status) {
            this.rows = res.data.data
          } else {
            this.rows = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    openModal() {
      if (!this.instituteId) {
        FemsToastrService.error('Institute ID must be selected')
        return
      }
      this.resetFintech()
      this.getFintech()
      this.getSettlementAccountName()
      this.$bvModal.show('fintech-merchant-add-modal')
    },
    getSettlementAccountName() {
      FintechMerchantService.settlementAccount(this.instituteId).then(res => {
        this.getSettlement_account_id = res.data.data
      })
    },
    getFintech() {
      FintechMerchantService.fintech().then(res => {
        this.getFintech_id = res.data.data
      })
    },
    getServiceById(id) {
      FintechMerchantService.fintechService(id).then(res => {
        this.payment_service_category_id = null
        this.getPayment_service_category_id = res.data.data
      })
    },

    checkFormValidity() {
      this.fintech_idState = null
      this.payment_service_category_idState = null
      this.merchant_nameState = null
      this.settlement_account_idState = null

      if (!this.instituteId) {
        FemsToastrService.error('Institution ID must be selected')
        return false
      }

      if (this.fintech_id == null || this.fintech_id === '') {
        this.fintech_idState = false
      }
      if (this.payment_service_category_id == null || this.payment_service_category_id === '') {
        this.payment_service_category_idState = false
      }
      if (this.merchant_name == null || this.merchant_name === '') {
        this.merchant_nameState = false
      }
      if (this.settlement_account_id == null || this.settlement_account_id === '') {
        this.settlement_account_idState = false
      }

      if (this.fintech_idState !== false && this.payment_service_category_idState !== false && this.merchant_nameState !== false
        && this.settlement_account_idState !== false) {
        return true
      }
      return false
    },
    handleFintechFormSubmit() {
      if (this.updateId) {
        this.updateFintech()
      } else {
        this.saveFintech()
      }
    },
    saveFintech() {
      if (!this.checkFormValidity()) {
        return
      }
      const params = {
        fintech_id: this.fintech_id,
        payment_service_category_id: this.payment_service_category_id,
        merchant_name: this.merchant_name,
        settlement_account_id: this.settlement_account_id,
        institution_id: this.instituteId,
      }
      FintechMerchantService.save(params).then(res => {
        if (res.data.status) {
          this.resetFintech()
          this.getFintechGridDataByInstituteId()
          FemsToastrService.success(res?.data.message)
          this.$nextTick(() => {
            this.$bvModal.hide('fintech-merchant-add-modal')
          })
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.success(err?.message)
        })
    },
    updateFintech() {
      if (!this.checkFormValidity()) {
        return
      }
      const params = {
        fintech_id: this.fintech_id,
        payment_service_category_id: this.payment_service_category_id,
        merchant_name: this.merchant_name,
        settlement_account_id: this.settlement_account_id,
        institution_id: this.instituteId,
      }
      FintechMerchantService.update(this.updateId, params).then(res => {
        if (res.data.status) {
          this.resetFintech()
          this.getFintechGridDataByInstituteId()
          FemsToastrService.success(res?.data.message)
          this.$nextTick(() => {
            this.$bvModal.hide('fintech-merchant-add-modal')
          })
        } else {
          FemsToastrService.error(res?.data.message)
        }
      })
        .catch(err => {
          FemsToastrService.success(err?.message)
        })
    },
    resetFintech() {
      this.updateId = null
      this.fintechFormData = {}
      this.fintech_id = ''
      this.getFintech_id = []
      this.payment_service_category_id = ''
      this.getPayment_service_category_id = []
      this.merchant_name = ''
      this.settlement_account_id = ''
      this.getSettlement_account_id = []
      this.fintech_idState = null
      this.payment_service_category_idState = null
      this.merchant_nameState = null
      this.settlement_account_idState = null
    },
    editRow(rowData) {
      this.resetFintech()
      this.getFintech()
      this.getSettlementAccountName()
      this.updateId = rowData.id
      this.fintech_id = rowData.fintech_id
      this.payment_service_category_id = rowData.payment_service_category_id
      this.merchant_name = rowData.merchant_name
      this.settlement_account_id = rowData.settlement_account_id

      FintechMerchantService.getOne(rowData.fintech_id).then(res => {
        this.getPayment_service_category_id = res.data.data
      })
      this.$bvModal.show('fintech-merchant-add-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          FintechMerchantService.delete(rowData.id).then(res => {
            if (res.status && res.data.status) {
              this.resetFintech()
              this.getFintechGridDataByInstituteId()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res?.data.message)
            }
          })
        }
      })
    },
    refreshGrid() {
      this.getFintechGridDataByInstituteId()
    },
    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
.v-select.style-chooser.vs--single.vs--searchable {
  width: 282px;
}
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
